const requireContext = require.context('./sect2', false, /.*\.js$/);
const modulesTemp = requireContext
  .keys()
  .map((file) => [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)])
  .reduce((modules, [name, module]) => {
    if (module.namespaced === undefined) {
      module.namespaced = true;
    }
    // if()

    return { ...modules, [name]: module };
  }, {});

export const modules = modulesTemp;
export const state = () => ({
  isTouched: false,

  // propertyUuid:null,
  // selectedCountry:null,
  // isTouched:false,
});

export const getters = {
  formData(state, getters) {
    const detail = getters['detail/formData'];
    const media = getters['media/formData'];
    return Object.assign({}, detail, media);
  },
};

export const mutations = {
  SET_IS_TOUCHED(state, payload) {
    state.isTouched = payload;
  },
};

export const actions = {
  restoreInitialState({ commit, dispatch }) {
    commit('SET_IS_TOUCHED', false);
    dispatch('detail/restoreInitialState');
    dispatch('media/restoreInitialState');
  },
  async applyEditData({ dispatch }, { data }) {
    await dispatch('detail/applyEditData', {
      data: data,
    });
    await dispatch('media/applyEditData', {
      data: data,
    });
  },
  async applyBaseData({ dispatch }, { form, baseData }) {
    await dispatch('detail/applyBaseData', {
      form,
      data: baseData,
    });
  },
};
