export const state = () => ({
  loading: false,
  products: [],
  paymentMethod: 'permata_va',
});

export const mutations = {
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  SET_PRODUCTS(state, payload) {
    state.products = payload;
  },
  SET_PAYMENT_METHOD(state, payload) {
    state.paymentMethod = payload;
  },
};

export const actions = {
  restoreInitialState({ commit }) {
    commit('SET_PAYMENT_METHOD', 'permata_va');
    commit('SET_PRODUCTS', []);
  },

  async submit(
    { commit, state, rootState, dispatch },
    { totalNetPrice, directActivation, duration },
  ) {
    try {
      commit('SET_LOADING', true);
      let { data } = await this.$http.post('/api/orders', {
        products: state.products.map((product) => {
          return {
            uuid: product.uuid,
            qty: product.qty,
            duration: duration,
            direct_activation: directActivation ? directActivation === 'true' : null,
          };
        }),
        payment_method: totalNetPrice > 0 ? state.paymentMethod : null,
        promo_code: rootState.checkout.promo.valid ? rootState.checkout.promo.promoCode : null,
      });
      return data;
    } catch (e) {
      console.log('ERROR SUBMIT PAY: ', e);
      if (e.response.status === 462) {
        await dispatch(
          'checkout/promo/checkPromo',
          {
            promoCode: rootState.checkout.promo.promoCode,
            productUuid: state.products[0].uuid,
            directActivation: directActivation ? directActivation === 'true' : null,
            qty: state.products[0].qty,
            duration: duration,
          },
          { root: true },
        );
      }
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
