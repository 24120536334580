const requireContext = require.context('./checkout', false, /.*\.js$/);
const modulesTemp = requireContext
  .keys()
  .map((file) => [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)])
  .reduce((modules, [name, module]) => {
    if (module.namespaced === undefined) {
      module.namespaced = true;
    }
    return { ...modules, [name]: module };
  }, {});

export const modules = modulesTemp;

export const state = () => ({
  loading: false,
  checkoutInfo: null,
});

export const getters = {};

export const mutations = {
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  SET_CHECKOUT_INFO(state, payload) {
    state.checkoutInfo = payload;
  },
};

export const actions = {
  async getCheckoutInfo({ commit, rootState }, { product, token, renewalType, qty, duration }) {
    try {
      commit('SET_LOADING', true);
      let options = {};
      if (token) {
        options.headers = {
          Authorization: `Bearer ${token}`,
        };
      } else {
        options.headers = {
          Authorization: `Bearer ${rootState.global.token}`,
        };
      }
      let { data } = await this.$http.post(
        '/api/orders/get_checkout',
        [
          {
            uuid: product.uuid,
            qty: qty ? qty : 1,
            duration: duration,
            direct_activation: renewalType ? renewalType.id === 1 : null,
          },
        ],
        options,
      );
      data.duration = duration;
      commit('SET_CHECKOUT_INFO', data);
      if (data && data.items) {
        commit('pay/SET_PRODUCTS', data.items);
      }
      return data;
    } catch (e) {
      commit('SET_CHECKOUT_INFO', null);
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
