import Vue from 'vue';
import VueMq from 'vue-mq';
Vue.use(VueMq, {
  breakpoints: {
    // default breakpoints - customize this
    xs: 427,
    sm: 769,
    md: 1023,
    lg: Infinity,
  },
  defaultBreakpoint: 'default', // customize this for SSR
});
