const requireContext = require.context('./listingForm', false, /.*\.js$/);
const modulesTemp = requireContext
  .keys()
  .map((file) => [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)])
  .reduce((modules, [name, module]) => {
    if (module.namespaced === undefined) {
      module.namespaced = true;
    }
    return { ...modules, [name]: module };
  }, {});

export const modules = modulesTemp;
export const state = () => ({
  listingUuid: null,
  status: 'DRAFT',
  isLoading: false,
  fetchingEditListingData: false,
  form: '',
  page: '',
  formCurrentStep: 1,
  listingNumber: null,
});

export const getters = {
  allFormData(state, getters) {
    const sect1 = getters['sect1/formData'];
    const sect2 = getters['sect2/formData'];
    const listingUuid = state.listingUuid;
    if (listingUuid !== null) {
      return Object.assign({ uuid: listingUuid }, sect1, sect2);
    }
    return Object.assign({ uuid: null }, sect1, sect2);
  },
};

export const mutations = {
  SET_FETCHING_EDIT_LISTING_DATA(state, payload) {
    state.fetchingEditListingData = payload;
  },
  SET_FORM_CURRENT_STEP(state, form) {
    state.formCurrentStep = form;
  },
  SET_FORM(state, form) {
    state.form = form;
  },
  SET_PAGE(state, page) {
    state.page = page;
  },
  SET_STATUS(state, page) {
    state.status = page;
  },
  SET_LISTING_UUID(state, listingUuid) {
    state.listingUuid = listingUuid;
  },
  SET_LISTING_NUMBER(state, listingNumber) {
    state.listingNumber = listingNumber;
  },
  SET_LOADING(state, isLoading) {
    console.log('LOADING: ', isLoading);
    state.isLoading = isLoading;
  },
};

export const actions = {
  async postData({ commit, getters }, action) {
    try {
      let formData = getters.allFormData;
      formData.action = action;
      let url = '/api/v2/listing_form/save';
      const {
        data: { data: responseData },
      } = await this.$http.post(url, formData);
      console.log('responseee', responseData);
      //
      commit('SET_LISTING_UUID', responseData.uuid);
      commit('SET_STATUS', responseData.status);
      return true;
    } catch (e) {
      throw e;
    }
  },

  async applyBaseData({ dispatch, state, commit }, baseData) {
    commit('SET_LISTING_NUMBER', null);
    return new Promise(async (resolve, reject) => {
      try {
        const form = state.form;
        const applySect1 = dispatch('sect1/applyBaseData', {
          form,
          baseData,
        });
        const applySect2 = dispatch('sect2/applyBaseData', {
          form,
          baseData,
        });

        await Promise.all[(applySect1, applySect2)];
        resolve();
      } catch (e) {
        reject();
      }
    });
  },

  async applyEditData({ commit, dispatch }, editData) {
    await dispatch('sect1/applyEditData', {
      data: editData,
    });
    await dispatch('sect2/applyEditData', {
      data: editData,
    });
    commit('SET_STATUS', editData.status);
    commit('SET_LISTING_NUMBER', editData.listing_number);
  },

  async fetchBaseData({ commit, dispatch, state }) {
    const form = state.form;
    let responseData;
    if (form === 'add') {
      const {
        data: { data: resultData },
      } = await this.$http.get('/api/v2/listing_form/add');
      responseData = resultData;
      await dispatch('applyBaseData', responseData);
      // commit('location/SET_PROPERTY_TYPE_CATEGORY_GROUP_LIST',responseData.propertyTypeCategoryGroup);
      // commit('location/SET_PROPERTY_TYPE_CATEGORY_LIST',responseData.propertyTypeCategory);
    } else {
      commit('SET_FETCHING_EDIT_LISTING_DATA', true);
      const {
        data: { data: resultData },
      } = await this.$http.get('/api/v2/listing_form/edit/' + state.listingUuid);
      responseData = resultData;
      await dispatch('applyBaseData', responseData.base_data);
      await dispatch('applyEditData', responseData.edit_data);
    }
  },
  restoreInitialState({ commit, dispatch }) {
    commit('SET_FORM_CURRENT_STEP', 1);
    commit('SET_LISTING_UUID', null);
    commit('SET_STATUS', 'DRAFT');
    dispatch('sect1/restoreInitialState');
    dispatch('sect2/restoreInitialState');
  },
};
