export const state = () => ({
  loading: false,
  archive: false,
  read: null,
  leads: [],
  leadsMeta: null,
  page: 1,
  selectedLead: null,
});

export const getters = {};

export const mutations = {
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  SET_ARCHIVE(state, payload) {
    state.archive = payload;
  },
  SET_READ(state, payload) {
    state.read = payload;
  },
  SET_LEADS(state, payload) {
    state.leads = payload;
  },
  SET_LEADS_META(state, payload) {
    state.leadsMeta = payload;
  },
  SET_PAGE(state, payload) {
    state.page = payload;
  },
  SET_SELECTED_LEAD(state, payload) {
    state.selectedLead = payload;
  },
  RESTORE_DEFAULT_VALUE(state) {
    state.archive = false;
    state.read = null;
    state.leads = [];
    state.leadsMeta = null;
    state.page = 1;
    state.selectedLead = null;
  },
};

export const actions = {
  async getEnquiries({ state, commit }, page) {
    try {
      commit('SET_LOADING', true);
      if (page) {
        commit('SET_PAGE', page);
      }
      const response = await this.$http.get('/api/enquiries', {
        params: {
          archive: state.archive,
          read: state.read,
          page: state.page,
        },
      });
      commit('SET_LEADS', response.data['data']);
      commit('SET_LEADS_META', response.data['meta']);
      return response.data;
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async setRead({ commit }, { uuid, value }) {
    try {
      commit('SET_LOADING', true);
      const response = await this.$http.post('/api/enquiries/set_read', {
        uuid: uuid,
        value: value,
      });
      return response.data;
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async setArchive({ commit }, { uuid, value }) {
    try {
      commit('SET_LOADING', true);
      const response = await this.$http.post('/api/enquiries/set_archive', {
        uuid: uuid,
        value: value,
      });
      return response.data;
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async deleteEnquiry({ commit }, { uuid }) {
    try {
      commit('SET_LOADING', true);
      const response = await this.$http.delete('/api/enquiries/' + uuid);
      return response.data;
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
