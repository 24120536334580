export const state = () => ({
  loading: false,
  categories: [
    {
      id: 0,
      name: 'Aktif',
      value: 'active',
    },
    {
      id: 1,
      name: 'Draft',
      value: 'draft',
    },
    {
      id: 2,
      name: 'Lampau',
      value: 'past',
    },
  ],
  category: {
    id: 0,
    name: 'Aktif',
    value: 'active',
  },
  sortByList: [
    { id: 1, name: 'newest', sort: 'date', order: 'desc' },
    { id: 2, name: 'oldest', sort: 'date', order: 'asc' },
    { id: 3, name: 'lowestPrice', sort: 'price', order: 'asc' },
    { id: 4, name: 'highestPrice', sort: 'price', order: 'desc' },
    { id: 5, name: 'expiredOldest', sort: 'expired', order: 'asc' },
    { id: 6, name: 'expiredNewest', sort: 'expired', order: 'desc' },
  ],
  sortBy: { id: 1, name: 'newest', sort: 'date', order: 'desc' },
  listings: [
    {
      uuid: '123789',
      previewLink: '',
      imgSource:
        'https://aseanlinked.s3.ap-southeast-3.amazonaws.com/public/img/placeholder-app.jpeg',
      listing_title: 'Ini Judul Listing',
      price_raw: 100000000,
      propertyType: 'Apartemen',
      visit: 20,
      impression: 150,
      isActivated: false,
    },
  ],
  listingsMeta: null,
  keyword: null,
  page: 1,
  searchQuery: null,
  selectedListings: [],
  repostSchedules: [],
  dayOfTheWeeks: [
    {
      id: 1,
      name: 'Senin',
    },
    {
      id: 2,
      name: 'Selasa',
    },
    {
      id: 3,
      name: 'Rabu',
    },
    {
      id: 4,
      name: 'Kamis',
    },
    {
      id: 5,
      name: 'Jumat',
    },
    {
      id: 6,
      name: 'Sabtu',
    },
    {
      id: 7,
      name: 'Minggu',
    },
  ],
  times: [
    {
      name: '00:00',
    },
    {
      name: '00:30',
    },
    {
      name: '01:00',
    },
    {
      name: '01:30',
    },
    {
      name: '02:00',
    },
    {
      name: '02:30',
    },
    {
      name: '03:00',
    },
    {
      name: '03:30',
    },
    {
      name: '04:00',
    },
    {
      name: '04:30',
    },
    {
      name: '05:00',
    },
    {
      name: '05:30',
    },
    {
      name: '06:00',
    },
    {
      name: '06:30',
    },
    {
      name: '07:00',
    },
    {
      name: '07:30',
    },
    {
      name: '08:00',
    },
    {
      name: '08:30',
    },
    {
      name: '09:00',
    },
    {
      name: '09:30',
    },
    {
      name: '10:00',
    },
    {
      name: '10:30',
    },
    {
      name: '11:00',
    },
    {
      name: '11:30',
    },
    {
      name: '12:00',
    },
    {
      name: '12:30',
    },
    {
      name: '13:00',
    },
    {
      name: '13:30',
    },
    {
      name: '14:00',
    },
    {
      name: '14:30',
    },
    {
      name: '15:00',
    },
    {
      name: '15:30',
    },
    {
      name: '16:00',
    },
    {
      name: '16:30',
    },
    {
      name: '17:00',
    },
    {
      name: '17:30',
    },
    {
      name: '18:00',
    },
    {
      name: '18:30',
    },
    {
      name: '19:00',
    },
    {
      name: '19:30',
    },
    {
      name: '20:00',
    },
    {
      name: '20:30',
    },
    {
      name: '21:00',
    },
    {
      name: '21:30',
    },
    {
      name: '22:00',
    },
    {
      name: '22:30',
    },
    {
      name: '23:00',
    },
    {
      name: '23:30',
    },
  ],
});

export const getters = {};

export const mutations = {
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  SET_CATEGORIES(state, payload) {
    state.categories = payload;
  },
  SET_CATEGORY(state, payload) {
    state.category = payload;
    state.sortBy = { id: 1, name: 'newest', sort: 'date', order: 'desc' };
  },
  SET_SORT_BY_LIST(state, payload) {
    state.sortByList = payload;
  },
  SET_SORT_BY(state, payload) {
    state.sortBy = payload;
  },
  SET_LISTINGS(state, payload) {
    state.listings = payload;
  },
  SET_LISTINGS_META(state, payload) {
    state.listingsMeta = payload;
  },
  SET_KEYWORD(state, payload) {
    state.keyword = payload;
  },
  SET_PAGE(state, payload) {
    state.page = payload;
  },
  SET_SEARCH_QUERY(state, payload) {
    state.searchQuery = payload;
  },
  ADD_SELECTED_LISTINGS(state, payload) {
    let found = state.selectedListings.find(function (item) {
      return item.uuid === payload.uuid;
    });
    if (!found) {
      state.selectedListings.push(payload);
    }
  },
  REMOVE_SELECTED_LISTINGS(state, arrIndex) {
    state.selectedListings.splice(arrIndex, 1);
  },
  SET_SELECTED_LISTINGS(state, payload) {
    state.selectedListings = payload;
  },
  // eslint-disable-next-line no-unused-vars
  CLEAR_SELECTED_LISTINGS(state, payload) {
    state.selectedListings = [];
  },
  ADD_REPOST_SCHEDULES(state, payload) {
    state.repostSchedules.push(payload);
  },
  REMOVE_REPOST_SCHEDULES(state, arrIndex) {
    state.repostSchedules.splice(arrIndex, 1);
  },
  SET_REPOST_SCHEDULES(state, payload) {
    state.repostSchedules = payload;
  },
  INPUT_SCHEDULE_DAY_OF_THE_WEEK(state, { index, day_of_the_week }) {
    let repostSchedule = JSON.parse(JSON.stringify(state.repostSchedules))[index];
    repostSchedule.day_of_the_week = day_of_the_week;
    state.repostSchedules.splice(index, 1, repostSchedule);
  },
  INPUT_SCHEDULE_TIME(state, { index, time }) {
    let repostSchedule = JSON.parse(JSON.stringify(state.repostSchedules))[index];
    repostSchedule.time = time;
    state.repostSchedules.splice(index, 1, repostSchedule);
  },
  INPUT_SCHEDULE_EXPIRED(state, { index, expired_at }) {
    let repostSchedule = JSON.parse(JSON.stringify(state.repostSchedules))[index];
    repostSchedule.expired_at = expired_at;
    state.repostSchedules.splice(index, 1, repostSchedule);
  },
};

export const actions = {
  async getAllListings({ state, commit }, { page, useLoading = true, forBulkRepost = false }) {
    try {
      commit('SET_LOADING', useLoading);
      if (page) {
        commit('SET_PAGE', page);
      }
      let { data } = await this.$http.get('/api/v2/catalog/items', {
        params: {
          category: state.category ? state.category.value : null,
          sort: state.sortBy ? state.sortBy.sort : null,
          order: state.sortBy ? state.sortBy.order : null,
          page: state.page,
          q: state.searchQuery,
          per_page: forBulkRepost ? (state.searchQuery ? 30 : 50) : null,
        },
      });
      commit('SET_LISTINGS', data.data);
      commit('SET_LISTINGS_META', data.meta);
      return data.data;
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async activate({ commit }, uuid) {
    try {
      commit('SET_LOADING', true);
      let { data } = await this.$http.post('/api/listings/activate', {
        uuid: uuid,
      });
      return data.data;
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async deactivate({ commit }, uuid) {
    try {
      commit('SET_LOADING', true);
      let { data } = await this.$http.post('/api/listings/deactivate', {
        uuid: uuid,
      });
      return data.data;
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async deleteListing({ commit }, uuid) {
    try {
      commit('SET_LOADING', true);
      let { data } = await this.$http.post('/api/listings/delete', {
        uuid: uuid,
      });
      return data.data;
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async repost({ commit }, uuid) {
    try {
      commit('SET_LOADING', true);
      let { data } = await this.$http.post('/api/listings/repost', {
        uuid: uuid,
      });
      return data.data;
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async bulkRepost({ commit }, uuids) {
    try {
      commit('SET_LOADING', true);
      let { data } = await this.$http.post('/api/listings/reposts', {
        uuids: uuids,
      });
      return data.data;
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async scheduleRepost({ state, commit }, uuid) {
    try {
      commit('SET_LOADING', true);
      let self = this;
      let { data } = await this.$http.post('/api/repost_schedules', {
        listing_uuid: uuid,
        schedules: state.repostSchedules.map(function (schedule) {
          console.log('EXPIRED AT: ', schedule.expired_at);
          let expiredAtDate = schedule.expired_at ? new Date(schedule.expired_at) : null;
          let expiredAt = self.$date.format(expiredAtDate, 'dd-MM-yyyy');
          return {
            day_of_the_week: schedule.day_of_the_week ? schedule.day_of_the_week.id : null,
            time: schedule.time ? schedule.time.name : null,
            expired_at: expiredAt,
          };
        }),
      });
      return data.data;
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async getRepostSchedules({ state, commit }, uuid) {
    try {
      commit('SET_LOADING', true);
      let { data } = await this.$http.get('/api/repost_schedules', {
        params: {
          listing_uuid: uuid,
        },
      });
      let value = data.data;
      commit(
        'SET_REPOST_SCHEDULES',
        value.map(function (schedule) {
          let expiredAt = schedule.expired_at ? schedule.expired_at.split('-') : null;
          return {
            day_of_the_week: schedule.day_of_the_week
              ? state.dayOfTheWeeks.find(function (dayOfTheWeek) {
                  return dayOfTheWeek.id === schedule.day_of_the_week;
                })
              : null,
            time: schedule.time
              ? state.times.find(function (time) {
                  return time.name === schedule.time;
                })
              : null,
            expired_at: expiredAt ? new Date(expiredAt[2], expiredAt[1] - 1, expiredAt[0]) : null,
          };
        }),
      );
      return value;
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  restoreInitialState({ commit }) {
    commit('SET_CATEGORY', {
      id: 0,
      name: 'Aktif',
      value: 'active',
    });
    commit('SET_SORT_BY', { id: 1, name: 'newest', sort: 'date', order: 'desc' });
    commit('SET_LISTINGS', []);
    commit('SET_LISTINGS_META', null);
    commit('SET_KEYWORD', null);
    commit('SET_PAGE', 1);
    commit('SET_SEARCH_QUERY', null);
  },
};
