export const state = () => ({
  loading: false,
  currentAmount: 0,
  quota: 0,
});

export const getters = {};

export const mutations = {
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  SET_CURRENT_AMOUNT(state, payload) {
    state.currentAmount = payload;
  },
  SET_QUOTA(state, payload) {
    state.quota = payload;
  },
};

export const actions = {
  async getListingAvailability({ commit }) {
    try {
      commit('SET_LOADING', true);
      const { data } = await this.$http.get('/api/listings/availability');
      commit('SET_CURRENT_AMOUNT', data.data.current_amount);
      commit('SET_QUOTA', data.data.quota);
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
