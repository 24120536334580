const requireContext = require.context('./sect1', false, /.*\.js$/);
const modulesTemp = requireContext
  .keys()
  .map((file) => [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)])
  .reduce((modules, [name, module]) => {
    if (module.namespaced === undefined) {
      module.namespaced = true;
    }
    // if()

    return { ...modules, [name]: module };
  }, {});

export const modules = modulesTemp;
export const state = () => ({
  isTouched: false,
});

export const getters = {
  formData(state, getters) {
    return getters['location/formData'];
  },
};

export const mutations = {
  SET_IS_TOUCHED(state, payload) {
    state.isTouched = payload;
  },
};

export const actions = {
  restoreInitialState({ commit, dispatch }) {
    commit('SET_IS_TOUCHED', false);
    dispatch('location/restoreInitialState');
  },
  async applyEditData({ dispatch }, { data }) {
    await dispatch('location/applyEditData', {
      data: data,
    });
  },
  // eslint-disable-next-line no-unused-vars
  async applyBaseData({ commit, dispatch, state, getters }, { form, baseData }) {
    await dispatch('location/applyBaseData', {
      form,
      data: baseData,
    });
    //
    // if (form === 'add') {
    //   commit('regionalListingForm/RESTORE_INITIAL_STATE');
    //   commit('regionalListingForm/SET_PROVINCES', baseData.provinces);
    //   commit('location/SET_SELECTED_PROPERTY_TYPE_CATEGORY_GROUP_LIST', 1);
    //   // if (state.location.officialPartnerAreas.length > 0) {
    //   //   commit(
    //   //     'location/SET_SELECTED_OFFICIAL_PARTNER_AREA',
    //   //     getters['location/allowedOfficialPartnerAreas'][0].uuid,
    //   //   );
    //   // }
    // }
    //
    // commit('location/SET_DEFAULT_ADD_STATE');
    // commit('basicInfo/SET_DEFAULT_VALUE');
  },
};
