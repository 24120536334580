import Vue from 'vue';
import swal from 'sweetalert2';

// Vue.use(VueSweetAlert);

const swalPlugin = {
  install(Vue) {
    Vue.prototype.$swal = swal;
  },
};
Vue.use(swalPlugin);
