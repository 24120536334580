export function getFieldRule(selectedPropertyType, fieldRules, type) {
  let propertyTypeId = null;
  if (selectedPropertyType) {
    propertyTypeId = selectedPropertyType.id;
  }
  if (!propertyTypeId) {
    return {
      allowed: false,
      required: false,
    };
  }
  let obj = fieldRules.find((o) => o.type === type && o.property_type_id == propertyTypeId);
  if (obj) {
    return {
      allowed: obj.allowed,
      required: obj.required,
    };
  } else {
    return {
      allowed: false,
      required: false,
    };
  }
}
