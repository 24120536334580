/* eslint-disable */
export default {
  beforeCreate({ app, router, route }) {
    if (process.client) {
      router.afterEach((to, from) => {
        if(to.hash){
          setTimeout(() => {
            const el = document.querySelector(to.hash);
            el && el.scrollIntoView();
          }, 1);
        }
      });
    }
  },
};