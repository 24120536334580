/* eslint-disable no-unused-vars,no-empty-pattern */
import CookieJs from 'js-cookie';

export const state = () => ({
  defaultLang: 'id',
  defaultUrl: 'https://belirumah.co',
  defaultType: 'website',
  defaultTitle:
    'BeliRumah.co - Jual Beli rumah dan jual beli apartemen menjadi mudah di BeliRumah!',
  defaultDescription:
    'Jual Beli rumah dan apartemen jadi nyata! BeliRumah membuat pencarian dan jual beli rumah dan apartemen menjadi lebih mudah dan praktis.',
  defaultOgImage:
    'https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/logo/v2/og-image-agen.png',
  content: {
    title: state.defaultTitle,
    htmlAttrs: {
      lang: CookieJs.get('locale') ? CookieJs.get('locale') : 'id',
    },
    meta: [
      { hid: 'og-url', property: 'og:url', content: state.defaultUrl },
      { hid: 'og-type', property: 'og:type', content: state.defaultType },
      {
        hid: 'og-description',
        property: 'og:description',
        content: state.defaultDescription,
      },
      {
        hid: 'description',
        name: 'description',
        content: state.defaultDescription,
      },
      {
        hid: 'og-image',
        property: 'og:image',
        content: state.defaultOgImage,
      },
      {
        hid: 'og-image-alt',
        property: 'og:image:alt',
        content: 'BeliRumah.co',
      },
    ],
    // script: [
    //   {
    //     src:
    //       'https://maps.googleapis.com/maps/api/js?key=' +
    //       'AIzaSyCrYqjA_5Jnbm_qygdHMY7vWgCvcMsoOI0' +
    //       '&libraries=places',
    //     defer: true,
    //   },
    // ],
  },
});
export const mutations = {
  SET_CONTENT(state, payload) {
    state.content = payload;

    if (!state.content.htmlAttr) {
      state.content.htmlAttrs = {};
    }
    if (!state.content.htmlAttrs.lang) {
      state.content.htmlAttrs.lang = CookieJs.get('locale') ? CookieJs.get('locale') : 'id';
    }

    if (state.content.meta) {
      if (!state.content.meta.some((el) => el.hid === 'og-url')) {
        state.content.meta.push({
          hid: 'og-url',
          property: 'og:url',
          content: state.defaultUrl,
        });
      }
      if (!state.content.meta.some((el) => el.hid === 'og-type')) {
        state.content.meta.push({
          hid: 'og-type',
          property: 'og:type',
          content: state.defaultType,
        });
      }
      if (!state.content.meta.some((el) => el.hid === 'og-image')) {
        state.content.meta.push({
          hid: 'og-image',
          property: 'og:image',
          content: state.defaultOgImage,
        });
      }
      if (!state.content.meta.some((el) => el.hid === 'og-image-alt')) {
        state.content.meta.push({
          hid: 'og-image-alt',
          property: 'og:image:alt',
          content: 'BeliRumah.co',
        });
      }
    }
  },
  RESTORE_DEFAULT_VALUE(state, route) {
    const title =
      'BeliRumah.co - Jual Beli rumah dan jual beli apartemen menjadi mudah di BeliRumah!';
    let addOn = '';
    if (route.name) {
      let words = route.name.split('-');
      for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
      }
      addOn += ` | ${words.join(' ')}`;
    }
    state.content = {
      title: title + addOn,
      htmlAttrs: {
        lang: CookieJs.get('locale') ? CookieJs.get('locale') : 'id',
      },
      meta: [
        { hid: 'og-url', property: 'og:url', content: state.defaultUrl + route.fullPath },
        { hid: 'og-type', property: 'og:type', content: state.defaultType },
        {
          hid: 'og-description',
          property: 'og:description',
          content: state.defaultDescription + addOn,
        },
        {
          hid: 'description',
          name: 'description',
          content: state.defaultDescription + addOn,
        },
        {
          hid: 'og-image',
          property: 'og:image',
          content: state.defaultOgImage,
        },
        {
          hid: 'og-image-alt',
          property: 'og:image:alt',
          content: 'BeliRumah.co',
        },
      ],
      link: [
        {
          rel: 'canonical',
          href: state.defaultUrl + route.fullPath,
          id: 'canonical',
        },
      ],
    };
  },
};
