export const state = () => ({
  validationErrors: null,
});

export const mutations = {
  SET_ERROR(state, payload) {
    state.validationErrors = payload;
  },
  CLEAR_ERROR(state) {
    state.validationErrors = null;
  },
};
