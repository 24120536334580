export const state = () => ({
  provinces: [],
  province: null,
  cities: [],
  city: null,
  areas: [],
  area: null,
});

export const getters = {
  formData(state) {
    return {
      area_id: state.area ? state.area.id : state.area,
    };
  },
};

export const mutations = {
  SET_PROVINCES(state, payload) {
    state.provinces = payload;
  },
  SET_PROVINCE(state, payload) {
    state.province = payload;
  },
  SET_CITY(state, payload) {
    state.city = payload;
  },
  SET_AREA(state, payload) {
    state.area = payload;
  },
  SET_CITIES(state, payload) {
    state.cities = payload;
  },
  SET_AREAS(state, payload) {
    state.areas = payload;
  },
};

export const actions = {
  restoreInitialState({ commit }) {
    commit('SET_PROVINCE', null);
    commit('SET_CITY', null);
    commit('SET_AREA', null);
    commit('SET_PROVINCES', []);
    commit('SET_CITIES', []);
    commit('SET_AREAS', []);
  },
  changeProvince({ commit, state }, payload) {
    if (payload) {
      if (payload.hasOwnProperty('id')) {
        commit('SET_PROVINCE', payload);
      } else {
        const pId = payload;
        let selectedProvince = state.provinces.find(function (item) {
          return item.id == pId;
        });
        if (!selectedProvince) {
          selectedProvince = null;
        }
        commit('SET_PROVINCE', selectedProvince);
      }
    } else {
      commit('SET_PROVINCE', null);
    }
  },
  changeCity({ commit, state }, payload) {
    if (payload) {
      if (payload.hasOwnProperty('id')) {
        commit('SET_CITY', payload);
      } else {
        const cId = payload;
        let selectedCity = state.cities.find(function (item) {
          return item.id == cId;
        });
        if (!selectedCity) {
          selectedCity = null;
        }
        commit('SET_CITY', selectedCity);
      }
    } else {
      commit('SET_CITY', null);
    }
  },
  changeArea({ commit, state }, payload) {
    if (payload) {
      if (payload.hasOwnProperty('id')) {
        commit('SET_AREA', payload);
      } else {
        const dId = payload;
        let selectedArea = state.areas.find(function (item) {
          return item.id == dId;
        });
        if (!selectedArea) {
          selectedArea = null;
        }
        commit('SET_AREA', selectedArea);
      }
    } else {
      commit('SET_AREA', null);
    }
  },

  getProvinces({ commit }) {
    this.$http.get('/api/v1/getAllProvince', {}).then((response) => {
      commit('SET_PROVINCES', response.data);
    });
  },

  async getCities({ state, commit, dispatch }) {
    commit('SET_CITIES', []);
    commit('SET_AREAS', []);

    dispatch('changeCity', null);
    dispatch('changeArea', null);

    await this.$http
      .get('/api/regional/get-cities-by-province', {
        params: {
          province_id: state.province.id,
        },
      })
      .then((result) => {
        commit('SET_CITIES', result.data.data);
      });
  },

  async getAreas({ state, commit, dispatch }) {
    commit('SET_AREAS', []);
    dispatch('changeArea', null);
    await this.$http
      .get('/api/regional/get-areas-by-city', {
        params: {
          city_id: state.city.id,
        },
      })
      .then((result) => {
        commit('SET_AREAS', result.data.data);
      });
  },

  applyEditData({ commit, dispatch }, { data }) {
    console.log('appy regional', data.regional_data);
    commit('SET_PROVINCES', data.regional_data.provinces);
    commit('SET_CITIES', data.regional_data.cities);
    commit('SET_AREAS', data.regional_data.areas);

    dispatch('changeProvince', data.regional_data.province);
    dispatch('changeCity', data.regional_data.city);
    dispatch('changeArea', data.regional_data.area);
  },
};
