export const state = () => ({
  loading: false,
  detail: null,
});

export const mutations = {
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  SET_DETAIL(state, payload) {
    state.detail = payload;
  },
};

export const actions = {
  restoreInitialState({ commit }) {
    commit('SET_DETAIL', null);
  },
  async getTakeover({ commit }) {
    try {
      commit('SET_LOADING', true);
      let { data } = await this.$http.get('/api/agent_banner/takeover', {
        params: {
          type: 'AGENT',
        },
      });
      let payload = data.data ? data.data[0] : null;
      commit('SET_DETAIL', payload);
      return payload;
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
