export default async ({ store, redirect }) => {
  let membership = store.state.global.user.membership;
  if (
    membership !== null &&
    membership.type !== null &&
    membership.type.toUpperCase() === 'TRIAL'
  ) {
    redirect('/upgrade');
  }
};
