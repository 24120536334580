<template>
  <default-layout>
    <section class="error--page py-5">
      <div class="error-container">
        <div class="text-center mb-5">
          <div class="title--text error--code">404</div>
          <div class="subtitle">{{ $t('errorPage.pageNotFound') }}</div>
        </div>
        <img
          width="300"
          src="https://s3-ap-southeast-1.amazonaws.com/rentfix/static/img/icon/error.svg"
        />
      </div>
    </section>
  </default-layout>
</template>

<script>
import DefaultHeadMixin from '@/mixins/defaultHead';
import DefaultLayout from '@/layouts/default';

export default {
  mixins: [DefaultHeadMixin],
  components: { DefaultLayout },
};
</script>

<style></style>
