import Vue from 'vue';
import App from './App.vue';
import createRouter from './router';
import createStore from './store/index';
import VueMeta from 'vue-meta';
import './registerServiceWorker';
import CookieJs from 'js-cookie';
import i18n from './plugins/i18n';
import './plugins/SimpleVueValidator';
import './plugins/VueModal';
import './plugins/VueSweetAlert2';
import './plugins/VueLodash';
import './plugins/VueObserve';
import './plugins/VueDeviceDetector';
import './plugins/VueClampy';
import './plugins/VueMq';
import { sync } from 'vuex-router-sync';

import './plugins/VueDatePicker';
import './assets/scss/app.scss';
import './assets/plugins/ionicons/css/ionicons.min.css';

import ClientOnly from 'vue-client-only';
import vuetify from './plugins/vuetify';
Vue.config.productionTip = false;
// Vue.config.devtools = true
/**
 * Vue meta plugin installation
 */
Vue.use(VueMeta, {
  keyName: 'head',
});

Vue.component('client-only', ClientOnly);

export default () => {
  const store = createStore();
  const router = createRouter();
  // eslint-disable-next-line no-unused-vars
  const unsync = sync(store, router);
  // createGtag(router);
  const app = new Vue({
    router,
    store,
    i18n,
    vuetify,
    render: (h) => h(App),
  });
  let locale;
  locale = CookieJs.get('locale') ? CookieJs.get('locale') : 'id';
  CookieJs.set('locale', locale);
  store.commit('global/SET_LOCALE', locale);
  i18n.locale = locale;

  return app;
};
