// export default function({ store, next }) {
//   if (store.state.global.user) {
//     if (store.state.global.user.admin_project_partner_url) {
//       return next(`/${store.state.global.user.admin_project_partner_url}/dashboard`);
//     }
//   }
//   return next();
// }
export default async ({ store, redirect }) => {
  if (store.state.global.user) {
    if (store.state.global.user.admin_project_partner_url) {
      return redirect(`/${store.state.global.user.admin_project_partner_url}/dashboard`);
    }
  }
};
