export const state = () => ({
  loading: false,
  accountProducts: [],
  coinProducts: [],
  allowCoin: false,
  allowCoinMsg: null,
  upgradeProducts: [],
  selectedProduct: null,
  allowUpgrade: false,
  allowUpgradeMsg: null,
  renewalProducts: [],
  allowRenewal: false,
  allowRenewalMsg: null,
  renewalType: null,
  duration: null,
  listingAvailabilityProducts: [],
  allowListingAvailability: false,
  allowListingAvailabilityMsg: null,
  selectedQty: null,
  selectedDuration: null,
});

export const getters = {
  allowedMainProducts(state) {
    let products = [];
    for (let i = 0; i < state.accountProducts.length; i++) {
      if (state.accountProducts[i].allowed) {
        products.push(state.accountProducts[i]);
      }
    }
    return products;
  },
  firstRowProducts(state) {
    let products = [];
    for (let i = 0; i < 2 && i < state.accountProducts.length; i++) {
      products.push(state.accountProducts[i]);
    }
    return products;
  },
  secondRowProducts(state) {
    let products = [];
    if (state.accountProducts.length > 2) {
      for (let i = 2; i < state.accountProducts.length; i++) {
        products.push(state.accountProducts[i]);
      }
    }
    return products;
  },
};

export const mutations = {
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  SET_ACCOUNT_PRODUCTS(state, payload) {
    state.accountProducts = payload;
  },
  SET_COIN_PRODUCTS(state, payload) {
    state.coinProducts = payload;
  },
  SET_ALLOW_COIN(state, payload) {
    state.allowCoin = payload;
  },
  SET_ALLOW_COIN_MSG(state, payload) {
    state.allowCoinMsg = payload;
  },
  SET_UPGRADE_PRODUCTS(state, payload) {
    state.upgradeProducts = payload;
  },
  SET_SELECTED_PRODUCT(state, payload) {
    state.selectedProduct = payload;
  },
  SET_ALLOW_UPGRADE(state, payload) {
    state.allowUpgrade = payload;
  },
  SET_ALLOW_UPGRADE_MSG(state, payload) {
    state.allowUpgradeMsg = payload;
  },
  CLEAR_ALLOW_UPGRADE(state) {
    state.allowUpgrade = false;
    state.allowUpgradeMsg = null;
  },
  SET_RENEWAL_PRODUCTS(state, payload) {
    state.renewalProducts = payload;
  },
  SET_ALLOW_RENEWAL(state, payload) {
    state.allowRenewal = payload;
  },
  SET_ALLOW_RENEWAL_MSG(state, payload) {
    state.allowRenewalMsg = payload;
  },
  CLEAR_ALLOW_RENEWAL(state) {
    state.allowRenewal = false;
    state.allowRenewalMsg = null;
  },
  SET_RENEWAL_TYPE(state, payload) {
    state.renewalType = payload;
  },
  SET_DURATION(state, payload) {
    state.duration = payload;
  },
  SET_LISTING_AVAILABILITY_PRODUCTS(state, payload) {
    state.listingAvailabilityProducts = payload;
  },
  SET_ALLOW_LISTING_AVAILABILITY(state, payload) {
    state.allowListingAvailability = payload;
  },
  SET_ALLOW_LISTING_AVAILABILITY_MSG(state, payload) {
    state.allowListingAvailabilityMsg = payload;
  },
  SET_SELECTED_QTY(state, payload) {
    state.selectedQty = payload;
  },
  SET_SELECTED_DURATION(state, payload) {
    state.selectedDuration = payload;
  },
};

export const actions = {
  async initProductPage({ state, dispatch, commit }, { membership }) {
    commit('SET_SELECTED_PRODUCT', null);
    commit('CLEAR_ALLOW_UPGRADE');
    commit('CLEAR_ALLOW_RENEWAL');
    let promises = [dispatch('getMainPackages', true)];
    if (membership) {
      promises.push(dispatch('checkIfCoinPackageAllowed'));
      promises.push(dispatch('checkIfUpgradePackageAllowed'));
      promises.push(dispatch('checkIfRenewPackageAllowed'));
      promises.push(dispatch('checkIfListingAvailabilityAllowed'));
    }
    try {
      commit('SET_LOADING', true);
      await Promise.all(promises);
      if (state.allowCoin) {
        await dispatch('getCoinPackages');
      }
      if (state.allowUpgrade) {
        await dispatch('getUpgradePackages');
      }
      if (state.allowRenewal) {
        await dispatch('getRenewalPackages');
      }
      if (state.allowListingAvailability) {
        await dispatch('getListingAvailabilities');
      }
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async getMainPackages({ commit }, forCheckout = false) {
    try {
      let { data } = await this.$http.get('/api/products/main_packages', {
        params: {
          for_checkout: forCheckout,
        },
      });
      commit('SET_ACCOUNT_PRODUCTS', data.data);
      return data.data;
    } catch (e) {
      if (!e.response && e.response.status !== 401) {
        throw e;
      } else {
        commit('SET_ACCOUNT_PRODUCTS', []);
      }
    }
  },
  async getCoinPackages({ commit }) {
    try {
      let { data } = await this.$http.get('/api/products/coins');
      commit('SET_COIN_PRODUCTS', data.data);
      return data.data;
    } catch (e) {
      if (!e.response && e.response.status !== 401) {
        throw e;
      } else {
        commit('SET_COIN_PRODUCTS', []);
      }
    }
  },
  async checkIfCoinPackageAllowed({ commit }) {
    try {
      let { data } = await this.$http.get('/api/products/coins/is_allowed');
      commit('SET_ALLOW_COIN', data.data.allowed);
      commit('SET_ALLOW_COIN_MSG', data.data.message);
      return data.data;
    } catch (e) {
      if (!e.response && e.response.status !== 401) {
        throw e;
      } else {
        commit('SET_ALLOW_COIN', false);
      }
    }
  },
  async getUpgradePackages({ commit }) {
    try {
      let { data } = await this.$http.get('/api/products/upgrade_main_packages');
      commit('SET_UPGRADE_PRODUCTS', data.data);
      return data.data;
    } catch (e) {
      if (!e.response && e.response.status !== 401) {
        throw e;
      } else {
        commit('SET_UPGRADE_PRODUCTS', []);
      }
    }
  },
  async checkIfUpgradePackageAllowed({ commit }) {
    try {
      let { data } = await this.$http.get('/api/products/upgrade_main_packages/is_allowed');
      commit('SET_ALLOW_UPGRADE', data.data.allowed);
      commit('SET_ALLOW_UPGRADE_MSG', data.data.message);
      return data.data;
    } catch (e) {
      if (!e.response && e.response.status !== 401) {
        throw e;
      } else {
        commit('SET_ALLOW_UPGRADE', false);
      }
    }
  },
  async getRenewalPackages({ commit }) {
    try {
      let { data } = await this.$http.get('/api/products/renewal_main_packages');
      commit('SET_RENEWAL_PRODUCTS', data.data);
      return data.data;
    } catch (e) {
      if (!e.response && e.response.status !== 401) {
        throw e;
      } else {
        commit('SET_RENEWAL_PRODUCTS', []);
      }
    }
  },
  async checkIfRenewPackageAllowed({ commit }) {
    try {
      let { data } = await this.$http.get('/api/products/renewal_main_packages/is_allowed');
      commit('SET_ALLOW_RENEWAL', data.data.allowed);
      commit('SET_ALLOW_RENEWAL_MSG', data.data.message);
      return data.data;
    } catch (e) {
      if (!e.response && e.response.status !== 401) {
        throw e;
      } else {
        commit('SET_ALLOW_RENEWAL', false);
      }
    }
  },
  async getDuration({ commit }, directActivation) {
    try {
      let { data } = await this.$http.get('/api/products/renewal_main_packages/duration', {
        params: { direct_activation: directActivation },
      });
      commit('SET_DURATION', data.data);
      return data.data;
    } catch (e) {
      if (!e.response && e.response.status !== 401) {
        throw e;
      } else {
        commit('SET_DURATION', null);
      }
    }
  },
  async getListingAvailabilities({ commit }) {
    try {
      let { data } = await this.$http.get('/api/products/listing_availabilities');
      commit('SET_LISTING_AVAILABILITY_PRODUCTS', data.data);
      return data.data;
    } catch (e) {
      if (!e.response && e.response.status !== 401) {
        throw e;
      } else {
        commit('SET_LISTING_AVAILABILITY_PRODUCTS', []);
      }
    }
  },
  async checkIfListingAvailabilityAllowed({ commit }) {
    try {
      let { data } = await this.$http.get('/api/products/listing_availabilities/is_allowed');
      commit('SET_ALLOW_LISTING_AVAILABILITY', data.data.allowed);
      commit('SET_ALLOW_LISTING_AVAILABILITY_MSG', data.data.message);
      return data.data;
    } catch (e) {
      if (!e.response && e.response.status !== 401) {
        throw e;
      } else {
        commit('SET_ALLOW_LISTING_AVAILABILITY', false);
      }
    }
  },
};
