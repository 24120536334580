export const state = () => ({
  isLoading: false,
  promoCode: null,
  discountAmount: null,
  discountPercentage: null,
  errors: null,
  requirements: [],
  valid: false,
});

export const mutations = {
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },
  SET_PROMO_CODE(state, payload) {
    state.promoCode = payload;
  },
  SET_DISCOUNT_AMOUNT(state, payload) {
    state.discountAmount = payload;
  },
  SET_DISCOUNT_PERCENTAGE(state, payload) {
    state.discountPercentage = payload;
  },
  SET_ERRORS(state, payload) {
    state.errors = payload;
  },
  SET_REQUIREMENTS(state, payload) {
    state.requirements = payload;
  },
  SET_VALID(state, payload) {
    state.valid = payload;
  },
};

export const actions = {
  async restoreInitialState({ commit }) {
    commit('SET_PROMO_CODE', null);
    commit('SET_DISCOUNT_AMOUNT', null);
    commit('SET_DISCOUNT_PERCENTAGE', null);
    commit('SET_ERRORS', null);
    commit('SET_REQUIREMENTS', []);
    commit('SET_VALID', false);
  },
  async checkPromo({ commit }, { promoCode, productUuid, directActivation, qty, duration }) {
    try {
      commit('SET_LOADING', true);
      const { data } = await this.$http.post('/api/promos/check', {
        promo_code: promoCode,
        product_uuid: productUuid,
        direct_activation: directActivation,
        qty: qty,
        duration: duration,
      });
      console.log('data', data);
      if (data && data.data) {
        commit('SET_DISCOUNT_AMOUNT', data.data.discount_amount);
        commit('SET_DISCOUNT_PERCENTAGE', data.data.discount_percentage);
        commit('SET_ERRORS', data.data.errors);
        commit('SET_REQUIREMENTS', data.data.requirements);
        commit('SET_VALID', data.data.valid);
        return data.data;
      }
      return data;
      // eslint-disable-next-line no-empty
    } catch (e) {
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
