export const state = () => ({
  loadingCreatePassword: false,
  agentTypes: [],
  areaSpecList: [],
  selectedAreaSpec: [],
  agentOffices: [],
});

export const getters = {};

export const mutations = {
  SET_LOADING_CREATE_PASSWORD(state, payload) {
    state.loadingCreatePassword = payload;
  },
  SET_AGENT_TYPES(state, payload) {
    state.agentTypes = payload;
  },
  SET_AREA_SPEC_LIST(state, payload) {
    state.areaSpecList = payload;
  },
  SET_SELECTED_AREA_SPEC(state, payload) {
    state.selectedAreaSpec = payload;
  },
  SET_AGENT_OFFICES(state, payload) {
    state.agentOffices = payload;
  },
};

export const actions = {
  async createPassword({ commit }, password) {
    try {
      commit('SET_LOADING_CREATE_PASSWORD', true);
      const { data } = await this.$http.post('/api/create_password', {
        password,
      });
      return data.data;
    } finally {
      commit('SET_LOADING_CREATE_PASSWORD', false);
    }
  },

  // eslint-disable-next-line no-unused-vars
  async resendActivation({ commit }, email) {
    try {
      const { data } = await this.$http.post('/api/auth/resend_activation', {
        email,
      });
      return data.data;
    } catch (e) {
      throw e;
    }
  },

  // eslint-disable-next-line no-unused-vars
  async getRegisterForm({ commit }) {
    try {
      const { data } = await this.$http.get('/api/agent/auth/register/get_form');
      let list = data.data ? data.data.agent_types : null;
      let newList = [];
      if (list) {
        for (let item of list) {
          newList.push(item);
        }
      }
      commit('SET_AGENT_TYPES', newList);

      list = data.data ? data.data.cities : null;
      newList = [];
      if (list) {
        for (let item of list) {
          newList.push(item);
        }
      }
      commit('SET_AREA_SPEC_LIST', newList);

      list = data.data ? data.data.agent_offices : null;
      newList = [];
      if (list) {
        for (let item of list) {
          newList.push(item);
        }
      }
      commit('SET_AGENT_OFFICES', newList);
      return newList;
    } catch (e) {
      throw e;
    }
  },
};
