export const state = () => ({
  loading: false,
  categories: [
    {
      id: 0,
      name: 'Semua',
      value: null,
    },
    {
      id: 1,
      name: 'Sedang Berlangsung',
      value: 'on_going',
    },
    {
      id: 2,
      name: 'Selesai',
      value: 'completed',
    },
  ],
  category: {
    id: 0,
    name: 'Semua',
    value: null,
  },
  orders: [],
  ordersMeta: null,
  keyword: null,
  page: 1,
  invoice: null,
});

export const getters = {};

export const mutations = {
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  SET_CATEGORIES(state, payload) {
    state.categories = payload;
  },
  SET_CATEGORY(state, payload) {
    state.category = payload;
  },
  SET_ORDERS(state, payload) {
    state.orders = payload;
  },
  SET_ORDERS_META(state, payload) {
    state.ordersMeta = payload;
  },
  SET_KEYWORD(state, payload) {
    state.keyword = payload;
  },
  SET_PAGE(state, payload) {
    state.page = payload;
  },
  SET_INVOICE(state, payload) {
    state.invoice = payload;
  },
};

export const actions = {
  async restoreInitiateState({ commit }) {
    commit('SET_CATEGORY', {
      id: 0,
      name: 'Semua',
      value: null,
    });
    commit('SET_ORDERS', []);
    commit('SET_ORDERS_META', null);
  },
  async getAllOrders({ state, commit }, page) {
    try {
      commit('SET_LOADING', true);
      if (page) {
        commit('SET_PAGE', page);
      }
      let { data } = await this.$http.get('/api/orders', {
        params: {
          q: state.keyword,
          status_filter: state.category ? state.category.value : null,
          page: state.page,
          // per_page: 2,
        },
      });
      commit('SET_ORDERS', data.data);
      commit('SET_ORDERS_META', data.meta);
      return data.data;
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async cancelOrder({ commit }, uuid) {
    try {
      commit('SET_LOADING', true);
      let { data } = await this.$http.post('/api/orders/cancel', {
        uuid: uuid,
      });
      return data;
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async getInvoice({ commit }, { uuid, token }) {
    try {
      let options = {};
      if (token) {
        options.headers = {
          Authorization: `Bearer ${token}`,
        };
      }
      let { data } = await this.$http.get(`/api/orders/invoices/${uuid}`, options);
      console.log('GET INVOICE: ', data.data);
      commit('SET_INVOICE', data.data);
      return data.data;
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
