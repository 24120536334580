// import Vue from 'vue'
// import Vuex from 'vuex'
// import actions from './actions'
// import mutations from './mutations'
// import getters from './getters'
//
// Vue.use(Vuex)
//
// export function createStore() {
//   return new Vuex.Store({
//     state: {
//       activeType: null,
//       itemsPerPage: 20,
//       items: {/* [id: number]: Item */},
//       users: {/* [id: string]: User */},
//       lists: {
//         top: [/* number */],
//         new: [],
//         show: [],
//         ask: [],
//         job: []
//       }
//     },
//     actions,
//     mutations,
//     getters
//   })
// }

import Vue from 'vue';

import Vuex from 'vuex';

Vue.use(Vuex);

// Load store modules dynamically.
const requireContext = require.context('./modules', false, /.*\.js$/);

// console.log('REQUIRED CONTEXT:', requireContext.keys());
const modules = requireContext
  .keys()
  .map((file) => [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)])
  .reduce((modules, [name, module]) => {
    // console.log('PRINT NAME: ', name);
    // console.log('PRINT MODULE: ', module);
    if (module.namespaced === undefined) {
      module.namespaced = true;
    }
    // if()

    return { ...modules, [name]: module };
  }, {});

// export default new Vuex.Store({
//   modules
// })

export default () => {
  return new Vuex.Store({
    modules,
  });
};
