const requireContext = require.context('./location', false, /.*\.js$/);
const modulesTemp = requireContext
  .keys()
  .map((file) => [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)])
  .reduce((modules, [name, module]) => {
    if (module.namespaced === undefined) {
      module.namespaced = true;
    }
    return { ...modules, [name]: module };
  }, {});
export const modules = modulesTemp;

export const state = () => ({
  propertyTypeList: [],
  selectedPropertyType: null,

  address: null,
  categoryName: null, //Nama Perumahan / Cluster,
  unitNumber: null,
  blockNumber: null,
});

export const getters = {
  formData(state, getters) {
    const map = getters['map/formData'];
    const regional = getters['regional/formData'];
    let currentFormState = {
      property_type_id: state.selectedPropertyType ? state.selectedPropertyType.id : null,
      address: state.address,
      category_name: state.categoryName,
      block_number: state.blockNumber,
      unit_number: state.unitNumber,
    };

    return Object.assign(currentFormState, map, regional);
  },
};

export const mutations = {
  SET_PROPERTY_TYPE_LIST(state, payload) {
    state.propertyTypeList = payload;
  },
  SET_SELECTED_PROPERTY_TYPE(state, payload) {
    state.selectedPropertyType = payload;
  },
  SET_ADDRESS(state, payload) {
    state.address = payload;
  },
  SET_CATEGORY_NAME(state, payload) {
    state.categoryName = payload;
  },
  SET_UNIT_NUMBER(state, payload) {
    state.unitNumber = payload;
  },
  SET_BLOCK_NUMBER(state, payload) {
    state.blockNumber = payload;
  },
};

export const actions = {
  restoreInitialState({ commit, dispatch }) {
    commit('SET_PROPERTY_TYPE_LIST', []);
    commit('SET_SELECTED_PROPERTY_TYPE', null);
    commit('SET_ADDRESS', null);
    commit('SET_CATEGORY_NAME', null);
    commit('SET_UNIT_NUMBER', null);
    commit('SET_BLOCK_NUMBER', null);
    dispatch('map/restoreInitialState');
    dispatch('regional/restoreInitialState');
  },

  async applyBaseData({ commit }, { form, data }) {
    commit('SET_PROPERTY_TYPE_LIST', data.property_types);
    if (form === 'add') {
      commit('regional/SET_PROVINCES', data.provinces);
    }
  },

  async applyEditData({ commit, dispatch, state }, { data }) {
    const selectedPropertyType = state.propertyTypeList.find(
      (elem) => elem.id === data.property_type_id,
    );
    commit('SET_SELECTED_PROPERTY_TYPE', selectedPropertyType);
    await dispatch('map/applyEditData', {
      data: data,
    });
    await dispatch('regional/applyEditData', {
      data: data,
    });

    commit('SET_ADDRESS', data.address);
    commit('SET_CATEGORY_NAME', data.category_name);
    commit('SET_UNIT_NUMBER', data.unit_number);
    commit('SET_BLOCK_NUMBER', data.block_number);
  },
};
