import { getFieldRule } from '@/utils/listingFormUtils'; // Adjust the path based on your project structure

export const state = () => ({
  fieldRules: [],

  certificateList: [],
  selectedCertificate: null,

  furnishingStatusList: [],
  selectedFurnishingStatus: null,

  facilities: [],
  title: null,
  description: null,
  youtubeUrl: null,
  price: null,
  bedroom: null,
  bathroom: null,
  buildingSize: null,
  landSize: null,
  totalFloor: null,
  floor: null,
  electricalPower: null,
  hoek: null,
  videos: [],
});

export const getters = {
  formData(state) {
    const facilities = JSON.parse(JSON.stringify(state.facilities));
    const selectedFacilities = facilities
      .filter((facility) => facility.selected)
      .map((selected) => {
        return {
          id: selected.id,
          amount: selected.amount === undefined ? null : selected.amount,
        };
      });

    let selectedHoek = null;
    if (state.hoek !== null) {
      selectedHoek = state.hoek === 1;
    }

    let videos = state.videos;
    let filteredVideos = videos.filter(function (video) {
      return video !== '' || video !== null;
    });

    let result = {
      certificate_id: state.selectedCertificate ? state.selectedCertificate.id : null,
      furnishing_status_id: state.selectedFurnishingStatus
        ? state.selectedFurnishingStatus.id
        : null,
      title_suffix: state.title,
      description: state.description,
      youtube_url: state.youtubeUrl,
      price: state.price,
      bedroom: state.bedroom,
      bathroom: state.bathroom,
      building_size: state.buildingSize,
      land_size: state.landSize,
      total_floor: state.totalFloor,
      floor: state.floor,
      electrical_power: state.electricalPower,
      hoek: selectedHoek,
      selected_facilities: selectedFacilities,
      videos: filteredVideos,
    };
    return result;
  },

  facilitiesWithUnit(state) {
    return state.facilities.filter(function (facility) {
      return facility.uom_name !== null;
    });
  },
  facilitiesWithoutUnit(state) {
    return state.facilities.filter(function (facility) {
      return facility.uom_name === null;
    });
  },
  bedroomRule(state, _, rootState) {
    return getFieldRule(
      rootState.listingForm.sect1.location.selectedPropertyType,
      state.fieldRules,
      'BEDROOM',
    );
  },
  bathroomRule(state, _, rootState) {
    return getFieldRule(
      rootState.listingForm.sect1.location.selectedPropertyType,
      state.fieldRules,
      'BATHROOM',
    );
  },

  blockNumberRule(state, _, rootState) {
    return getFieldRule(
      rootState.listingForm.sect1.location.selectedPropertyType,
      state.fieldRules,
      'UNIT_BLOCK',
    );
  },
  landSizeRule(state, _, rootState) {
    return getFieldRule(
      rootState.listingForm.sect1.location.selectedPropertyType,
      state.fieldRules,
      'LAND_SIZE',
    );
  },
  totalFloorRule(state, _, rootState) {
    return getFieldRule(
      rootState.listingForm.sect1.location.selectedPropertyType,
      state.fieldRules,
      'TOTAL_FLOOR',
    );
  },
  floorRule(state, _, rootState) {
    return getFieldRule(
      rootState.listingForm.sect1.location.selectedPropertyType,
      state.fieldRules,
      'FLOOR',
    );
  },
  buildingSizeRule(state, _, rootState) {
    return getFieldRule(
      rootState.listingForm.sect1.location.selectedPropertyType,
      state.fieldRules,
      'BUILDING_SIZE',
    );
  },
  electricalPowerRule(state, _, rootState) {
    return getFieldRule(
      rootState.listingForm.sect1.location.selectedPropertyType,
      state.fieldRules,
      'ELECTRICAL_POWER',
    );
  },
  hoekRule(state, _, rootState) {
    return getFieldRule(
      rootState.listingForm.sect1.location.selectedPropertyType,
      state.fieldRules,
      'HOEK',
    );
  },
  furnishingStatusRule(state, _, rootState) {
    return getFieldRule(
      rootState.listingForm.sect1.location.selectedPropertyType,
      state.fieldRules,
      'FURNISHING_STATUS',
    );
  },
};

export const mutations = {
  SET_TITLE(state, payload) {
    state.title = payload;
  },
  SET_DESCRIPTION(state, payload) {
    state.description = payload;
  },
  SET_YOUTUBE_URL(state, payload) {
    state.youtubeUrl = payload;
  },

  SET_PRICE(state, payload) {
    state.price = payload;
  },

  SET_BEDROOM(state, payload) {
    state.bedroom = payload;
  },

  SET_BATHROOM(state, payload) {
    state.bathroom = payload;
  },

  SET_BUILDING_SIZE(state, payload) {
    state.buildingSize = payload;
  },
  SET_LAND_SIZE(state, payload) {
    state.landSize = payload;
  },
  SET_TOTAL_FLOOR(state, payload) {
    state.totalFloor = payload;
  },
  SET_FLOOR(state, payload) {
    state.floor = payload;
  },

  SET_ELECTRICAL_POWER(state, payload) {
    state.electricalPower = payload;
  },

  SET_HOEK(state, payload) {
    state.hoek = payload;
  },

  SET_FIELD_RULES(state, payload) {
    state.fieldRules = payload;
  },
  SET_CERTIFICATE_LIST(state, payload) {
    state.certificateList = payload;
  },
  SET_SELECTED_CERTIFICATE(state, payload) {
    state.selectedCertificate = payload;
  },
  SET_FURNISHING_STATUS_LIST(state, payload) {
    state.furnishingStatusList = payload;
  },
  SET_SELECTED_FURNISHING_STATUS(state, payload) {
    state.selectedFurnishingStatus = payload;
  },

  SET_FACILITIES(state, payload) {
    state.facilities = payload.map(function (item) {
      item.selected = false;
      if (item.uom_name !== null) {
        item.amount = null;
      }
      return item;
    });
  },
  TOGGLE_SELECTED_FACILITY(state, facilityId) {
    const facility = state.facilities.find(function (facility) {
      return facility.id == facilityId;
    });
    facility.selected = !facility.selected;

    if (!facility.selected) {
      if (facility.uom_name !== null) {
        facility.amount = null;
      }
    }
  },
  SET_FACILITY_AMOUNT(state, { facilityId, amount }) {
    let facility = state.facilities.find(function (fac) {
      return fac.id == facilityId;
    });
    facility.amount = amount;
  },

  SET_VIDEOS(state, videos) {
    let temp = [];
    videos.forEach(function (element) {
      temp.push(element);
    });
    state.videos = temp;
  },
  REMOVE_VIDEO(state, index) {
    if (state.videos.length > 0) {
      state.videos.splice(index, 1);
    }
  },
  ADD_VIDEO(state) {
    if (state.videos.length < 5) {
      if (!state.videos) {
        state.videos = [];
      }
      state.videos.push('');
    }
  },
  INPUT_VIDEO(state, payload) {
    state.videos.splice(payload.index, 1, payload.input);
  },
};

export const actions = {
  restoreInitialState({ commit }) {
    commit('SET_TITLE', null);
    commit('SET_DESCRIPTION', null);
    commit('SET_YOUTUBE_URL', null);
    commit('SET_PRICE', null);
    commit('SET_BEDROOM', null);
    commit('SET_BATHROOM', null);
    commit('SET_BUILDING_SIZE', null);
    commit('SET_LAND_SIZE', null);
    commit('SET_TOTAL_FLOOR', null);
    commit('SET_FLOOR', null);
    commit('SET_ELECTRICAL_POWER', null);
    commit('SET_HOEK', null);
    commit('SET_FIELD_RULES', []);
    commit('SET_CERTIFICATE_LIST', []);
    commit('SET_SELECTED_CERTIFICATE', null);
    commit('SET_FURNISHING_STATUS_LIST', []);
    commit('SET_SELECTED_FURNISHING_STATUS', null);
    commit('SET_FACILITIES', []);
    commit('SET_VIDEOS', []);
  },

  // eslint-disable-next-line no-unused-vars
  async applyBaseData({ commit }, { form, data }) {
    console.log('kqweiei', data);
    commit('SET_CERTIFICATE_LIST', data.certificates);
    commit('SET_FIELD_RULES', data.field_rules);
    commit('SET_FURNISHING_STATUS_LIST', data.furnishing_statuses);
    commit('SET_FACILITIES', data.facilities);
  },

  applyEditData({ state, commit }, { data }) {
    commit('SET_TITLE', data.title_suffix);
    commit('SET_DESCRIPTION', data.description);
    commit('SET_YOUTUBE_URL', data.youtube_url);
    commit('SET_PRICE', data.price);
    commit('SET_BEDROOM', data.bedroom);
    commit('SET_BATHROOM', data.bathroom);
    commit('SET_BUILDING_SIZE', data.building_size);
    commit('SET_LAND_SIZE', data.land_size);
    commit('SET_TOTAL_FLOOR', data.total_floor);
    commit('SET_FLOOR', data.floor);
    console.log('APPLY EDIT DATAAA:', data);
    commit('SET_ELECTRICAL_POWER', data.electrical_power);
    commit('SET_VIDEOS', data.videos);
    if (data.hoek === null || data.hoek === undefined) {
      commit('SET_HOEK', null);
    } else if (data.hoek === true) {
      commit('SET_HOEK', 1);
    } else {
      commit('SET_HOEK', 0);
    }

    const selectedFurnish = state.furnishingStatusList.find(
      (elem) => elem.id === data.furnishing_status_id,
    );
    commit('SET_SELECTED_FURNISHING_STATUS', selectedFurnish);

    const selectedCertificate = state.certificateList.find(
      (elem) => elem.id === data.certificate_id,
    );
    commit('SET_SELECTED_CERTIFICATE', selectedCertificate);

    for (let key in data.selected_facilities) {
      commit('TOGGLE_SELECTED_FACILITY', key);
      if (data.selected_facilities[key] != null) {
        commit('SET_FACILITY_AMOUNT', {
          facilityId: key,
          amount: parseInt(data.selected_facilities[key]),
        });
      }
    }
  },

  fieldAllowed({ state }, { type, propertyTypeId }) {
    if (!propertyTypeId) {
      return false;
    }
    let obj = state.fieldRules.find((o) => o.type === type && o.property_type_id == propertyTypeId);
    if (obj) {
      return obj.allowed;
    } else {
      return false;
    }
  },
};
