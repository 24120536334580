export const state = () => ({
  selectedHelp: null,
  helpList: [
    {
      url: 'account-registration',
      title: 'FAQ.accountRegistration',
    },
    {
      url: 'account-purchase',
      title: 'FAQ.accountPurchase',
    },
    {
      url: 'membership',
      title: 'FAQ.membership',
    },
    {
      url: 'upgrade',
      title: 'FAQ.upgrade',
    },
    {
      url: 'listing-slot',
      title: 'FAQ.listingSlot',
    },
    {
      url: 'coin',
      title: 'FAQ.coin',
    },
    {
      url: 'upload',
      title: 'FAQ.upload',
    },
    {
      url: 'repost',
      title: 'FAQ.repost',
    },
    {
      url: 'deactivate',
      title: 'FAQ.deactivate',
    },
    {
      url: 'catalog-link',
      title: 'FAQ.catalogLink',
    },
    {
      url: 'co-broke',
      title: 'FAQ.cobroke',
    },
    {
      url: 'edit-profile',
      title: 'FAQ.editProfile',
    },
    {
      url: 'referral-code',
      title: 'FAQ.referralCode',
    },
    {
      url: 'password',
      title: 'FAQ.password',
    },
    {
      url: 'coin-usage',
      title: 'FAQ.coinUsage',
    },
    {
      url: 'inbox',
      title: 'FAQ.inbox',
    },
  ],
});

export const getters = {};

export const mutations = {
  SET_SELECTED_HELP(state, payload) {
    state.selectedHelp = payload;
  },
};

export const actions = {};
