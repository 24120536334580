export const state = () => ({
  name: '',
  email: '',
  phone: '',
  password: '',
});

export const getters = {};

export const mutations = {
  SET_NAME(state, payload) {
    state.name = payload;
  },
  SET_EMAIL(state, payload) {
    state.email = payload;
  },
  SET_PHONE(state, payload) {
    state.phone = payload;
  },
  SET_PASSWORD(state, payload) {
    state.password = payload;
  },
  RESTORE_INIT_VALUES(state) {
    state.name = '';
    state.email = '';
    state.phone = '';
    state.password = '';
  },
};

export const actions = {
  // async getProjects({commit,rootState}, requestData) {
  //     await this.$axios.get('/api/v1/tipeproperti/'+requestData.tipeproperti+'/projects', {
  //         params : {
  //             page : requestData.page,
  //             q : rootState.searchNavbar.cityId===''||rootState.searchNavbar.cityId===null?rootState.searchNavbar.q:null,
  //             city : rootState.searchNavbar.cityId,
  //             building_size_min : rootState.searchNavbar.building_size_min,
  //             building_size_max : rootState.searchNavbar.building_size_max,
  //             propertyType : rootState.searchNavbar.propertyType,
  //             rentType : rootState.searchNavbar.rentType,
  //             rangePrice : rootState.searchNavbar.rangePrice,
  //             sortBy : rootState.searchNavbar.sortBy,
  //             sortOrder : rootState.searchNavbar.sortOrder,
  //         },
  //     }).then((response) => {
  //         commit('SET_PROJECTS', response.data);
  //     });
  // }
};
