import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const numberFormats = {
  'id-ID': {
    currency: {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0, // set fraction digits to 0 to remove cents
      maximumFractionDigits: 0,
    },
  },
};

function loadLocaleMessages() {
  // const locales = require.context('../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  // const messages = {};
  // locales.keys().forEach(key => {
  //   const matched = key.match(/([A-Za-z0-9-_]+)\./i);
  //   if (matched && matched.length > 1) {
  //     const locale = matched[1];
  //     messages[locale] = locales(key);
  //   }
  // });
  // return messages;
  return {
    id: Object.assign(require('@/locales/id.json'), {
      fields: {
        email: 'Email',
        password: 'Password',
        confirm_password: 'Konfirmasi Password',
        fullname: 'Nama Lengkap',
        old_pass: 'Password Lama',
        name: 'Nama',
        message: 'Pesan',
        province: 'Provinsi',
        city: 'Kota',
        district: 'Kecamatan',
        village: 'Kelurahan/Desa',
        agreement: 'Perjanjian',
        identity_card_number: 'Nomor Identitas (KTP/SIM/PASSPORT)',
        phone: 'Nomor Telepon/Handphone',
        address_line_2: 'Alamat',
        duration: 'Durasi',
        newMessage: 'Pesan Baru',
        nego_value: 'Harga Nego',
        profile: 'Profil',
        address: 'Alamat',
        industry: 'Industri Perusahaan',
        website: 'Website',
        size: 'Estimasi Luas',
        contact: 'Kontak',
        type: 'Tipe Perusahaan',
        estimate_pax: 'Estimasi Pax',
        monthly_budget: 'Anggaran Bulanan',
        yearly_budget: 'Anggaran Tahunan',
        property_type: 'Tipe Properti',
      },
    }),
  };
}

export default new VueI18n({
  numberFormats,
  locale: process.env.VUE_APP_I18N_LOCALE || 'id',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'id',
  messages: loadLocaleMessages(),
});
