export const state = () => ({
  lat: null,
  lng: null,
  usePinLocation: false,
  loading: false,
});

export const getters = {
  formData(state) {
    return {
      lat: state.lat,
      lng: state.lng,
    };
  },
};

export const mutations = {
  SET_LAT(state, payload) {
    state.lat = payload;
  },
  SET_USE_PIN_LOCATION(state, payload) {
    state.usePinLocation = payload;
  },
  SET_LNG(state, payload) {
    state.lng = payload;
  },
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
};

export const actions = {
  restoreInitialState({ commit }) {
    commit('SET_LAT', null);
    commit('SET_LNG', null);
    commit('SET_USE_PIN_LOCATION', false);
  },

  applyEditData({ commit }, { data }) {
    commit('SET_LAT', data.lat);
    commit('SET_LNG', data.lng);
    if (data.lat !== null) {
      commit('SET_USE_PIN_LOCATION', true);
    } else {
      commit('SET_USE_PIN_LOCATION', false);
    }
  },
};
